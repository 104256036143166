import NavBar from "./components/Navbar";
import Slide from "./components/SlideShow";
import Overview from "./components/Overview";
import CoreValues from "./components/Core_Values";
import Services from "./components/Services";
import Contact from "./components/Contact_Us";
import Footer from "./components/footer";
import Accordion from "./components/Projects";

function App() {
  return (
    <div name="Home">
      <NavBar />

      <div style={{ paddingTop: "5rem" }}>
        <Slide />
      </div>

      <div>
        <h1
          className="center-bold-gold"
          style={{ paddingTop: "5rem" }}
          name="Overview"
        >
          Overview
        </h1>
      </div>

      <Overview />

      <div>
        <h1
          className="center-bold-gold"
          style={{ paddingTop: "5rem" }}
          name="Core Values"
        >
          Core Values
        </h1>
      </div>

      <div className="background">
        <CoreValues />
      </div>

      <div>
        <h1
          className="center-bold-gold"
          style={{ paddingTop: "5rem" }}
          name="Services"
        >
          Our Services
        </h1>
      </div>

      <div className="background">
        <Services />
      </div>

      <div>
        <h1
          className="center-bold-gold"
          style={{ paddingTop: "5rem" }}
          name="Projects"
        >
          Our Projects
        </h1>
      </div>
      <div className="projects">
        <Accordion />
      </div>

      <div>
        <h1
          className="center-bold-gold"
          style={{ paddingTop: "5rem" }}
          name="Contact Us"
        >
          Contact Us
        </h1>
      </div>

      <Contact />

      <Footer />
    </div>
  );
}

export default App;
