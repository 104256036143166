
import React from 'react';
import constructionImage from '../assets/img/New Construction.jpg';
import rennovationImage from '../assets/img/Renovations.jpg';
import exteriorImage from '../assets/img/Exterior and Interior Work.jpg';
import electricalImage from '../assets/img/Electrical.jpg';
import { Card, CardContent, CardMedia, Typography, Container, Grid} from '@mui/material';

const Services = () => {

       const ServicesData = [
            {
              title: "New Construction",
              description: "Ground-up construction of residential, commercial, and institutional buildings."
            },
            {
              title: "Renovations",
              description: "Meticulous restoration and transformative renovations of existing structures."
              
            },
            {
              title: "Exterior and Interior",
              description: "A full spectrum of exterior facelifts and intricate interior enhancements."
            
            },

            {
              title: "Electrical and Hvac",
              description: "Excelling in providing electrical and HVAC services, ensuring top-tier installation, maintenance, and efficiency."
            
            }
          ];

          const imageMap = {
            'New Construction': constructionImage,
            'Renovations': rennovationImage,
            'Exterior and Interior': exteriorImage,
            'Electrical and Hvac': electricalImage
          };

return (
    <>
     <Container sx={{ py: 8 }}>
        <Grid container spacing={2} justifyContent="center">
          {ServicesData.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardMedia
                  component="img"
                  image={imageMap[service.title]}
                  sx={{
                    height: 200, // Fixed height for all images
                    objectFit: 'cover', // Cover the area of the card, cropping image if necessary
                    objectPosition: 'center', // Center the image within the card area
                    width: '100%'
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" style={{ color: '#424242', fontSize: 'medium' }}>
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Services;
