import React, { useState } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import {Link} from 'react-scroll'

import logoImage from '../assets/img/logo.png';

const NavBar = () =>{

    const [nav, setNav] = useState(false) 

    const links = [
        {
            id: 1, 
            link: 'Home'
        },

        {
            id: 2, 
            link: 'Overview'
        }, 

        {
            id: 3, 
            link: 'Core Values'
        }, 
        
        {
            id: 4, 
            link: 'Services'
        },

        {
            id: 5, 
            link: 'Projects'
        },

        {
            id: 6, 
            link: 'Contact Us'
        }
    ]

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed z-50"> 

    <div>
        {/* <h1 className='text-4xl ml-2 font-logo'> Scala Construction </h1> */}
        <img src={logoImage} alt="Scala Construction Logo" style={{ width: "300px" }} />
    </div>

    <ul className = 'hidden lg:flex m-0'>
        {links.map(({id, link}) => (
            <li key = {id} className='px-6 cursor-pointer text-white hover:scale-105 duration-200 text-lg underline-animation font-logo'>
                 <Link to={link} smooth duration={200}>
                    {link}
                 </Link>
            </li>
        ))}
    </ul>

    <div onClick = {() => setNav(!nav) } className='cursor-pointer pr-4 z-10 text-white lg:hidden'>
       {nav ? <FaTimes size = {30}/> : <FaBars size = {30}/> }
    </div>


{nav && (
    // <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen 
    // bg-gradient-to-b from-black to-gray-800 text-white'>
    //     {links.map(({id, link}) => (
    //         <li key = {id} className='px-4 cursor-pointer py-6 text-4xl hover:scale-105 duration-200 underline-animation'>
    //               <Link onClick = {() => setNav(!nav)} to={link} smooth duration={500}>
    //                 {link}
    //              </Link>
    //         </li>
    //     ))}
    // </ul>

    <ul className='flex flex-col justify-center items-center absolute inset-0 w-full h-screen 
    bg-gradient-to-b from-black to-gray-800 text-white pl-0'>
    {links.map(({id, link}) => (
        <li key={id} className='px-4 cursor-pointer py-6 text-4xl hover:scale-105 duration-200 underline-animation'>
            <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>
                {link}
            </Link>
        </li>
    ))}
</ul>

)}


    </div>
  )
}

export default NavBar