import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
// import Social from "./Social_Media_Box";
import { Link } from "react-scroll";

//import projectDetailsImage from '../assets/img/projects.jpg';

const Overview = () => {
  return (
    <div className="h-1/2 w-full bg-gradient-to-b from-custom-black via-custom-gray to-custom-black text-white pb-2">
      <div
        className="max-w-screen-lg mx-auto flex items-center justify-center px-4 md:flex-row
      p-0 m-0 "
      >
        {/* <div class="absolute top--3/4 left-0 hidden xl:block">
          <Social />
        </div> */}

        <div className="w-100 flex items-center justify-center flex-col">
          <p className="py-4 w-100 pb-0 mb-5 text-lg">
            <strong>Scala Construction,</strong> a leading general contractor
            based in the heart of New York City, brings over 30 years of
            unwavering dedication to the art and science of construction.
            Established in 1992, Scala Construction has evolved into a symbol of
            excellence, crafting innovative and enduring spaces across all five
            boroughs. Our commitment to precision, creativity, and client
            satisfaction defines our approach to every project.
            <p>
              Our extensive portfolio showcases the breadth and depth of Scala
              Construction's capabilities. From iconic skyscrapers in Manhattan
              to historic brownstone renovations in Brooklyn, each project is a
              testament to our craftsmanship, attention to detail, and ability
              to adapt to diverse environments.
            </p>
          </p>

          <div className="">
            <Link
              to="Projects"
              smooth
              duration={200}
              className="group text-white w-fit px-6 py-2 my-0.5 flex items-center rounded-md 
                bg-gradient-to-r from-gray-700 to-gray-200 cursor-pointer no-underline"
            >
              Our Projects
              <span className="group-hover:rotate-90 duration-300">
                <MdKeyboardArrowRight size={25} className="ml-1 pr-0" />
              </span>
            </Link>
          </div>

          {/* <div className="">
            <a
              href= {projectDetailsImage} // Replace with your image's actual path or URL
              target="_blank"
              rel="noopener noreferrer"
              smooth
              duration={200}
              className="group text-white w-fit px-6 py-2 my-0.5 flex items-center rounded-md bg-gradient-to-r from-gray-700 to-gray-200 cursor-pointer no-underline"
            >
              Project Details
              <span className="">
                <MdKeyboardArrowRight size={25} className="ml-1 pr-0" />
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Overview;
