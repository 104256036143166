import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Carousel from "react-bootstrap/Carousel";
import b2Image from "../assets/img/pics/b2.jpg"; // Import the image file
import b3Image from "../assets/img/pics/b3.jpg"; // Import the image file
import b1Image from "../assets/img/pics/b1.jpg"; // Import the image file

const Slide = () => {
  return (
    <Carousel fade className="w-100">
      <Carousel.Item>
        <img
          src={b1Image}
          alt="First slide"
          className="img-fluid carousel-image"
        />{" "}
        {/* Use the imported image */}
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={b2Image}
          alt="Second slide"
          className="img-fluid carousel-image"
        />{" "}
        {/* Use the imported image */}
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={b3Image}
          alt="Third slide"
          className="img-fluid carousel-image"
        />{" "}
        {/* Use the imported image */}
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
};

export default Slide; // Component names should start with an uppercase letter
