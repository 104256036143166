import img1 from "../assets/img/workPictures/b1_copy.jpg";
import img2 from "../assets/img/workPictures/b2_copy.jpg";
import img3 from "../assets/img/workPictures/b3_copy.jpg";
import img4 from "../assets/img/workPictures/b4.jpg";
import img5 from "../assets/img/workPictures/b5.jpg";
import img6 from "../assets/img/workPictures/b6.jpg";
import img7 from "../assets/img/workPictures/b7.jpg";
import img8 from "../assets/img/workPictures/b8.jpg";
import img9 from "../assets/img/workPictures/b9.jpg";

const Pictures = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column", // Arrange images in a row; use 'column' for vertical arrangement
      // justifyContent: "space-around",
      alignItems: "center",
      width: "100%", // Takes the full width of the page
      height: "100vh", // Takes the full height of the viewport
      margin: "0 auto",
    },
    image: {
      width: "50%", // Each image takes up half of the width of the container
      height: "auto", // Maintains the aspect ratio of the image
      padding: "10px", // Optional padding around images
    },
  };

  return (
    <div style={styles.container}>
      <h1>Our Work</h1>
      <img src={img1} alt="Project 1" style={styles.image} />
      <img src={img2} alt="Project 2" style={styles.image} />
      <img src={img3} alt="Project 2" style={styles.image} />
      <img src={img4} alt="Project 2" style={styles.image} />
      <img src={img5} alt="Project 2" style={styles.image} />
      <img src={img6} alt="Project 2" style={styles.image} />
      <img src={img7} alt="Project 2" style={styles.image} />
      <img src={img8} alt="Project 2" style={styles.image} />
      <img src={img9} alt="Project 2" style={styles.image} />
    </div>
  );
};

export default Pictures;
