import React, { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import ReactDOM from "react-dom";
import Overview from "./Pictures";
// AccordionItem Component
const AccordionItem = ({ address, owner, architect }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
        {address}
        <span className="toggle">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {owner && (
            <p className="paragContent">
              <b>Owner:</b> {owner}
            </p>
          )}
          {architect && (
            <p className="paragContent">
              <b>Architect:</b> {architect}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

// Accordion Component
const Accordion = () => {
  const accordionData = [
    {
      owner: "NEW WORLD COFFEE & BAGELS",
      architect: "KAMLESH SHAH DESIGNS 18 LOVELL DR PLAINBORO, NJ 08536",
      address: "37 3ᴿᴰ AVENUE NEW YORK, NY",
    },

    {
      owner: "ST. JOSEPH SERVICES FOR CHILDREN/FAMILIES",
      architect:
        "HENRY & DOOLEY ARCHITECTS P.C. 760 CLASSON AVENUE BROOKLYN NY 11238",
      address: "163 WILSON AVENUE BROOKLYN, NY 11237",
    },

    {
      owner: "PER SCHOLAS",
      architect:
        "AGUSTA ARCHITECTURAL & URBAN PLANNER PC 182-09 HORACE HARDING EXPWAY FRESH MEADOWS NY 11365",
      address: "890 CARRISON AVE. BRONX NY 10454",
    },

    {
      owner: "FRANK B. BELL FUNERAL HOME",
      architect:
        "HENRY & DOOLEY ARCHITECTS P.C. 760 CLASSON AVENUE BROOKLYN NY 11238",
      address: "536 STERLING PLACE BRONX NY 10454",
    },

    {
      // owner: 'OWNER?',
      architect:
        "HENRY & DOOLEY ARCHITECTS P.C. 760 CLASSON AVENUE BROOKLYN NY 11238",
      address: "SOBRO 370 E. 149ᵀᴴ STREET BRONX NY 10455",
    },

    {
      owner: "REACH CHARTER SCHOOL",
      architect:
        "URBAN ACHITECTURAL INITIATIVES 838 BROADWAY NEW YORK, NY 10003",
      address: "SOBRO 149ᵀᴴ STREET BRONX NY 10454",
    },

    {
      // owner: '???',
      // architect: '????',
      address: "1836 ALBANY AVE, BROOKLYN, NY 11210",
    },

    {
      // owner: '???',
      // architect: '????',
      address: "25-15 30ᵀᴴ AVE, ASTORIA, NY 11102",
    },

    {
      owner: "CLEVELAND VAUGHAN FUNERAL HOME",
      // architect: '????',
      address: "103 PUTNAM AVE, BROOKLYN, NY 11238",
    },
  ];
  const handleOpenPictures = () => {
    // Open a blank window
    const newWindow = window.open("", "_blank");

    // Create a new root div element
    const root = document.createElement("div");
    newWindow.document.body.appendChild(root);

    // Render the Pictures component into the new window
    ReactDOM.render(<Overview />, root);

    // Optionally, you could add some basic styles or a title
    newWindow.document.title = "Our Work";
    newWindow.document.body.style.margin = "0";
    newWindow.document.body.style.fontFamily = "Arial, sans-serif";
  };
  return (
    <section className="flex items-center justify-center flex-col">
      <div className="accordion">
        {accordionData.map((item, index) => (
          <AccordionItem
            key={index}
            owner={item.owner}
            architect={item.architect}
            address={item.address}
          />
        ))}
      </div>

      <div className="mt-5">
        <button
          onClick={handleOpenPictures}
          className="group text-white w-fit px-6 py-2 my-0.5 flex items-center rounded-md 
            bg-gradient-to-r from-gray-700 to-gray-200 cursor-pointer no-underline"
        >
          View our Work
          <span className="">
            <MdKeyboardArrowRight size={25} className="ml-1 pr-0" />
          </span>
        </button>
      </div>
    </section>
  );
};

export default Accordion;
