
import React from 'react';
import { Container, Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';

import excellenceImage from '../assets/img/Excellence.jpg';
import integrityImage from '../assets/img/Integrity.jpg';
import innovationImage from '../assets/img/Innovation.jpg';
import clientImage from '../assets/img/Client-Centric Approach.jpg';
import communityImage from '../assets/img/Community Engagement.jpg';
import safetyImage from '../assets/img/Safety and Sustainability.jpg';


const CoreValues = () => {

const imageMap = {
  'Excellence': excellenceImage,
  'Integrity': integrityImage,
  'Innovation': innovationImage,
  'Client-Centric Approach': clientImage,
  'Community Engagement': communityImage,
  'Safety and Sustainability': safetyImage
};

  const cards = [
    {
      title: 'Excellence',
      description: 'We strive for the highest standards in every aspect of our work, from project conceptualization to completion. Our commitment to excellence is the cornerstone of our reputation.'
    },
    {
      title: 'Integrity',
      description: 'Transparency, honesty, and ethical conduct are at the core of our business. We build trust through open communication and by consistently delivering on our promises.'
    },
    {
      title: 'Innovation',
      description: 'Scala Construction embraces innovation as a driving force behind our projects. We stay ahead of industry trends, adopt cutting-edge technologies, and bring creative solutions to every construction challenge.'
    },
    {
      title: 'Client-Centric Approach',
      description: 'Understanding and exceeding client expectations is fundamental to our success. We foster collaborative relationships, prioritize client needs, and ensure clear communication throughout the construction journey.'
    
    },
    {
      title: 'Community Engagement',
      description: 'We recognize our responsibility to the communities where we work. Scala Construction actively engages in initiatives that contribute positively to local environments, creating lasting social and economic impact.'
    
    },
    {
      title: 'Safety and Sustainability',
      description: 'Safety is paramount at Scala Construction. We adhere to rigorous safety standards to create secure working environments for our team and all stakeholders. Moreover, our commitment to sustainability is evident in our practices, where we integrate eco-friendly materials and energy-efficient technologies into our projects.'
      
    },
  ];

  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={imageMap[card.title]} 
                sx={{
                  height: 200, // Fixed height for all images
                  objectFit: 'cover', // Cover the area of the card, cropping image if necessary
                  objectPosition: 'center', // Center the image within the card area
                  width: '100%'
                }}

                
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography variant="body2"  style={{ color: '#424242', fontSize: '1.8ex' }}>
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CoreValues;





