import React from "react";
import Swal from "sweetalert2";
import { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const emailData = {
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: e.target.message.value,
    };

    // fetch("http://localhost:3001/send", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(emailData),
    // })

    fetch("/api/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // Handle the success case
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your email has been sent successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please Try Again.",
        });
      });

    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <section className="bg-black dark:bg-gray-900">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md pt-0">
        <p className="mb-4 lg:mb-16 font-light text-center text-gray-400 dark:text-gray-400 sm:text-xl">
          <strong>Scala Construction,</strong>invites you to join us in crafting
          the future of urban living. Whether you envision a groundbreaking new
          project or aspire to breathe new life into an existing space, Scala
          Construction is your trusted partner. Contact us today and let's
          embark on a construction journey where dreams become reality and
          legacies are built to last.
        </p>
        <form onSubmit={sendEmail} className="space-y-8 mt-0 pt-0">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-white dark:text-gray-300"
            >
              Email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-gray-500 block w-full p-2.5"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-white dark:text-gray-300"
            >
              Subject
            </label>
            <input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              type="text"
              name="subject"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-gray-500 block w-full p-2.5"
              placeholder="Let us know how we can help you"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-white dark:text-gray-400"
            >
              Your message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id="message"
              rows="6"
              name="message"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-gray-500 block w-full p-2.5"
              placeholder="Leave a comment..."
              required
            ></textarea>
          </div>
          {/* <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-neutral-200 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button> */}
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-neutral-200 sm:w-fit hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-gray-200 dark:focus:ring-primary-800"
          >
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
